import {inject, Injectable} from "@angular/core";
import {collection, doc, Firestore, onSnapshot, runTransaction} from "@angular/fire/firestore";
import {catchError, Observable} from "rxjs";
import {UserRoles, UserState} from "../user/user.store";
import {FirebaseUserWithConnectedRescueOrgs, OrganizationUser} from "../rescue-org/rescue-org.store";
import {fromPromise} from "rxjs/internal/observable/innerFrom";
import {UserQuery} from "../user/user.query";
import {
  rescueOrgAdminCollectionKey,
  rescueOrgCollectionKey,
  rescueOrgRescuerCollectionKey,
  userCollectionKey
} from "../../globalVariables";

@Injectable({ providedIn: 'root' })
export class RescueOrgUsersService {
  private firestore: Firestore = inject(Firestore);
  private userQuery = inject(UserQuery);
  private readonly userRef = collection(this.firestore, userCollectionKey);

  // Change the role of the connected user
  changeUserRole(newRole: UserRoles, user: OrganizationUser) {
    return fromPromise(
      runTransaction(this.firestore, async (transaction) => {
        if (user.role && newRole) {
          const userDocRef = doc(this.userRef, user.id);
          const userCurrentRecueOrgGroupRef = doc(
            this.firestore,
            rescueOrgCollectionKey,
            this.userQuery.selectedRescueOrgId,
            user.role,
            user.id
          );
          const userNewRescueOrgGroupRef = doc(
            this.firestore,
            rescueOrgCollectionKey,
            this.userQuery.selectedRescueOrgId,
            newRole,
            user.id
          );

          const userDoc = await transaction.get(userDocRef);
          const userData = (userDoc.data() as FirebaseUserWithConnectedRescueOrgs);

          const updatedOrgs = userData.connectedOrgs;
          updatedOrgs[this.userQuery.selectedRescueOrgId].role = newRole;

          transaction.delete(userCurrentRecueOrgGroupRef);
          transaction.set(userNewRescueOrgGroupRef, {
            fullName: user.fullName,
            email: user.email ? user.email : '',
            phone: user.phone ? user.phone : ''
          });
          transaction.update(userDocRef, {
            connectedOrgs: updatedOrgs
          });
        }
      })
    ).pipe(catchError((err) => {
      throw new Error(err);
    }))
  }

  getRescueOrgAdmins(orgId: string) {
    const adminsRef = collection(this.firestore, rescueOrgCollectionKey, orgId, rescueOrgAdminCollectionKey);

    return new Observable<UserState[]>((observer) => {
      const unsubscribe = onSnapshot(adminsRef, (snapshot) => {
        const data: any[] = [];
        snapshot.forEach((doc) => {
          data.push({ id: doc.id, ...doc.data() });
        });
        observer.next(data);
      }, (err) => {
        console.log(err);
      });

      return () => unsubscribe();
    });
  }

  getRescueOrgRescuers(orgId: string) {
    const rescuersRef = collection(this.firestore, rescueOrgCollectionKey, orgId, rescueOrgRescuerCollectionKey);

    return new Observable<UserState[]>((observer) => {
      const unsubscribe = onSnapshot(rescuersRef, (snapshot) => {
        const data: any[] = [];
        snapshot.forEach((doc) => {
          data.push({ id: doc.id, ...doc.data() });
        });
        observer.next(data);
      }, (err) => {
        console.log(err);
      });

      return () => unsubscribe();
    });
  }
}
